/*
 * Copyright (C) 2022 Adrien Fery. All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { graphql } from 'gatsby';
import { Link } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { SEO } from '../components/seo';

const NotFoundPage = () =>
{
  return (
    <>
      <SEO
        title="404 Not Found"
        noindex={true}
      ></SEO>
      <div className="h-screen bg-slate-900">
        <div className="min-h-full px-4 py-24 md:grid md:place-items-center lg:px-8">
          <div className="max-w-max mx-auto">
            <main className="sm:flex">
              <p className="text-4xl tracking-tight font-bold text-primary-500 sm:text-5xl sm:tracking-tight">404</p>
              <div className="sm:ml-6">
                <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                  <h1 className="text-4xl font-bold text-gray-50 tracking-tight sm:text-5xl sm:tracking-tight">
                    <Trans>Page not found</Trans>
                  </h1>
                  <p className="mt-1 text-base text-gray-50"><Trans>Please check the URL in the address bar and try again.</Trans></p>
                </div>
                <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                  <Link
                    to="/"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <Trans>Go back home</Trans>
                  </Link>

                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
